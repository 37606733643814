html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.gw-proxied,
.gw-proxied#card-number,
.gw-proxied#expiry-year,
.gw-proxied#security-code,
.gw-proxied#expiry-month {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}
iframe.gw-proxy-number,
iframe.gw-proxy-securityCode,
iframe.gw-proxy-expiryMonth,
iframe.gw-proxy-expiryYear {
  width: 85% !important;
}
iframe#redirectFrame {
  min-height: 100vh;
}
@media (min-width: 768px) {
  .gw-proxied,
  .gw-proxied#card-number,
  .gw-proxied#expiry-year,
  .gw-proxied#security-code,
  .gw-proxied#expiry-month {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
  }
  iframe.gw-proxy-number,
  iframe.gw-proxy-securityCode,
  iframe.gw-proxy-expiryMonth,
  iframe.gw-proxy-expiryYear {
    width: 100% !important;
  }
}
